import React, { Component } from 'react'

export class ModalDrawer extends Component {
  render() {
    return (
      <div>
        Drawer
      </div>
    )
  }
}

export default ModalDrawer
